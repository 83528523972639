/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import * as bootstrap from 'bootstrap';
require('./bootstrap');

window.Vue = require('vue');
window.Encoding = require('encoding-japanese');
window.bootstrap = bootstrap;

import { createApp } from 'vue'
import HistoryComponent from './components/HistoryComponent.vue'
import InquiryComponent from './components/InquiryComponent.vue'
import ListOfficeComponent from './components/ListOfficeComponent.vue'
import EditComponent from './components/EditComponent.vue'
import SearchComponent from './components/SearchComponent.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toasetOption = {
    position: "bottom-center",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: false,
    rtl: false,
    transition: "Vue-Toastification__fade",
};

createApp({
    components:{
        HistoryComponent,
        InquiryComponent,
        ListOfficeComponent,
        EditComponent,
        SearchComponent
    }
})
.use(Toast, toasetOption)
.mount('#app')
