<template>
    <table class="table table-bordered text-nowrap align-middle">
        <thead>
            <tr class="text-center">
                <th>申請種別</th>
                <th>代行料/税込み</th>
                <th>備考</th>
                <th v-if="isUpdateMode"></th>
            </tr>
        </thead>
        <tbody id="commission-details">

            <!-- 更新 -->
            <tr v-for="(item,i) in _items" v-bind:key="item.NO" v-if="isUpdateMode" >
                <td>
                    <input type="text" id="kinds" name="kinds" class="form-control" maxlength="50" v-model="item.KINDS">
                </td>
                <td class="text-end">
                    <input type="text" id="fee" name="fee" class="form-control text-end" maxlength="10" v-model="item.FEE">
                </td>
                <td>
                    <input type="text" id="fee-comment" name="fee-comment" class="form-control" maxlength="500" v-model="item.FEE_COMMENT">
                </td>
                <td>
                    <button id="delete" class="btn text-danger border-0" @click="deleteItem(i)"><i class="fa-solid fa-xmark fs-5"></i></button>
                </td>
            </tr>

            <!-- 照会 -->
            <tr v-for="item in _items" v-bind:key="item.NO" v-if="isSelectMode">
                <td>
                    <span id="kinds" name="kinds" class="caption">{{item.KINDS}}</span>
                </td>
                <td class="text-end">
                    <span id="fee" name="fee" class="caption">{{Number(item.FEE).toLocaleString()}}円</span>
                </td>
                <td>
                    <span id="fee-comment" name="fee-comment" class="caption">{{item.FEE_COMMENT}}</span>
                </td>
            </tr>

        </tbody>
    </table>
    <div class="d-flex justify-content-center">
        <button v-if="isUpdateMode" class="btn text-primary border-0 pt-0" @click="addItem()"><i class="fa-solid fa-circle-plus fs-2"></i></button>
    </div>
</template>

<script>
    export default {
        name: "App",
        components: {
        },
        props: {
            mode: String,
            items: Object,
        },
        data() {
            return {
                _mode: this.mode,
                _items: this.items,
            };
        },
        emits: [
            'updateFunc',
        ],
        methods : {
            addItem() {
                this._items.push({
                    OFFICEID: '',
                    NO: 0,
                    KINDS: '',
                    FEE: '',
                    FEE_COMMENT: '',
                });
                this.$emit('updateFunc', this._items);
            },
            deleteItem(index){
                this._items.splice(index, 1);
                this.$emit('updateFunc', this._items);
            }
        },
        computed: {
            isUpdateMode() {
                return (this._mode === 'update');
            },
            isSelectMode() {
                return (this._mode === 'select');
            },
        },
    };

</script>


