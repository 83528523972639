<template>
    <div class="alert alert-info alert-dismissible fade show" role="alert">
        <span class="icon-tips">Webサイトへ掲載する事務所情報の登録／編集を行います。</span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div class="mb-3">
        <!-- 掲載期限 -->
        <label for="LIMITDAY" class="form-label">掲載期限</label>
        <input type="date" class="form-control bg-white" style="width: 150px;" name="LIMITDAY" v-model="item.LIMITDAY" aria-label="掲載期間" aria-describedby="label-LIMITDAY">
    </div>
    <div class="mb-3">
        <!-- 掲載地域 -->
        <label class="form-label">掲載地域<span id="MessageForFreeMemberShip" class="alert-outline"><i class="fa fa-fw fa-solid fa-lock pe-2"></i>無料会員は１つのみ選択できます</span></label>
        <treeselect v-model="treedata" :multiple="true" :disable-branch-nodes="true" :value-consists-of="tree.valueConsistsOf" :options=tree.unydata placeholder="選択してください" />
    </div>
    
    <!-- 事務所名 行政書士名 登録番号 -->
    <div class="row g-3 mb-3">
        <div class="form-group col-lg-4">
            <label for="office-name" class="form-label">事務所名<span class="alert-outline">必須</span></label>
            <input type="text" id="office-name" class="form-control bg-white" v-model="item.OFFICENAME" aria-label="事務所名" aria-describedby="label-OFFICENAME">
        </div>
        <div class="form-group col-lg-4">
            <label for="member-name" class="form-label">行政書士名</label>
            <input type="text" id="member-name" class="form-control" name="NAME" v-model="member.NAME" aria-label="行政書士名" aria-describedby="label-NAME" readonly>
        </div>
        <div class="form-group col-lg-4">
            <label for="member-qualificationcd" class="form-label">登録番号</label>
            <input type="text" id="member-qualificationcd" class="form-control" name="QUALIFICATIONCD" v-model="member.QUALIFICATIONCD" aria-label="登録番号" aria-describedby="label-QUALIFICATIONCD">
        </div>
    </div>
    <!-- 郵便番号 住所 -->
    <div class="row g-3 mb-3">
        <div class="form-group col-lg-2">
            <label for="item-postnum" class="form-label">郵便番号<span class="alert-outline">必須</span></label>
            <input type="text" id="item-postnum" class="form-control bg-white" name="POSTNUM" v-model="item.POSTNUM" aria-label="郵便番号" aria-describedby="label-POSTNUM">
        </div>
        <div class="form-group col-lg-2">
            <label for="pref_id" class="form-label">住所<span class="alert-outline">必須</span></label>
            <select v-model="item.TDFK_ID" name="pref_id" class="form-select">
                <option v-for="pref in prefs" v-bind:value="pref.TDFK_ID">{{ pref.TDFK_NAME }} </option>
            </select>
        </div>
        <div class="form-group col-lg-8">
            <label for="item-address" class="form-label">&nbsp;</label>
            <input type="text" id="item-address" class="form-control bg-white" name="ADDRESS" v-model="item.ADDRESS" aria-label="住所" aria-describedby="label-ADDRESS">
        </div>
    </div>
    <!-- 電話 FAX 携帯電話 -->
    <div class="row g-3 mb-3">
        <div class="form-group col-lg-4">
            <div><label for="item-tel1" class="form-label">電話</label></div>
            <input type="tel" id="item-tel1" class="form-control bg-white" name="TEL1" v-model="item.TEL1" aria-label="電話" aria-describedby="label-TEL1">
        </div>
        <div class="form-group col-lg-4">
            <div><label for="item-fax" class="form-label">FAX</label></div>
            <input type="tel" id="item-fax" class="form-control bg-white" name="FAX" v-model="item.FAX" aria-label="FAX" aria-describedby="label-FAX">
        </div>
        <div class="form-group col-lg-4">
            <div><label for="item-tel2" class="form-label">携帯電話</label></div>
            <input type="tel" id="item-tel2" class="form-control bg-white" name="TEL2" v-model="item.TEL2" aria-label="携帯電話" aria-describedby="label-TEL2">
        </div>
    </div>
    <!-- メールアドレス URL -->
    <div class="row g-3 mb-3">
        <div class="form-group col-lg-4">
            <div><label for="item-email" class="form-label">E-MAIL</label></div>
            <input type="email" id="item-email" class="form-control bg-white" name="EMAIL" v-model="item.EMAIL" aria-label="E-MAIL" aria-describedby="label-EMAIL">
        </div>
        <div class="form-group col-lg-8">
            <div><label for="item-hp_url" class="form-label">URL</label></div>
            <input type="url" id="item-hp_url" class="form-control bg-white" name="HP_URL" v-model="item.HP_URL" aria-label="HP_URL" aria-describedby="label-HP_URL" maxlength="500">
        </div>
    </div>
    <!-- 撮影ボタン 画像出力ボックス -->
    <div class="row g-3 mb-3">
        <!-- 事務所紹介写真1 -->
        <div class="form-group col-lg-4">
            <label class="form-label">事務所紹介写真1</label>
            <div class="d-flex mb-3">
                <label class="btn btn-outline-secondary flex-fill py-1">
                    <i class="fa-fw fa-regular fa-image fs-1rem"></i> 画像を選択
                    <input type="file" id="pr1path_temp" class="d-none" accept="image/*" v-on:change="fileSelected">
                </label>
                <div class="text-center flex-fill">
                    <div class="btn-group">
                        <input type="radio" class="btn-check" id="PR1FLG_YES" name="PR1FLG" v-model="_item.PR1FLG" value="1" hidden>
                        <label for="PR1FLG_YES" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR1FLG) ? 'active' : '']" style="width: 70px;">
                        公開
                        </label>
                        <input type="radio" class="btn-check" id="PR1FLG_NO" name="PR1FLG" v-model="_item.PR1FLG" value="0" hidden>
                        <label for="PR1FLG_NO" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR1FLG) ? '' : 'active']" style="width: 70px;">
                        非公開
                        </label>
                    </div>
                </div>
            </div>
            <div class="thumb-box border rounded position-relative" :class="[!blobUrlPR1 ? 'd-flex justify-content-center align-items-center' : '']">
                <img v-if="blobUrlPR1" id="PR1PATH" :src="blobUrlPR1" alt="事務所紹介写真1">
                <button v-if="blobUrlPR1" id="pr1path_delete" class="btn text-danger position-absolute end-0 border-0" v-on:click="resetImage"><i class="fa-regular fa-circle-xmark fs-5"></i></button>
                <div v-else class="no-image">
                    <i class="fa fa-regular fa-image fa-2x"></i>
                    <div>No Image</div>
                </div>
            </div>
        </div>
        <!-- 事務所紹介写真2 -->
        <div class="form-group col-lg-4">
            <label class="form-label">事務所紹介写真2</label>
            <div class="d-flex mb-3">
                <label class="btn btn-outline-secondary flex-fill py-1">
                    <i class="fa-fw fa-regular fa-image fs-1rem"></i> 画像を選択
                    <input type="file" id="pr2path_temp" class="d-none" accept="image/*" v-on:change="fileSelected">
                </label>
                <div class="text-center flex-fill">
                    <div class="btn-group">
                        <input type="radio" class="btn-check" id="PR2FLG_YES" name="PR2FLG" v-model="_item.PR2FLG" value="1" hidden>
                        <label for="PR2FLG_YES" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR2FLG) ? 'active' : '']" style="width: 70px;">
                        公開
                        </label>
                        <input type="radio" class="btn-check" id="PR2FLG_NO" name="PR2FLG" v-model="_item.PR2FLG" value="0" hidden>
                        <label for="PR2FLG_NO" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR2FLG) ? '' : 'active']" style="width: 70px;">
                        非公開
                        </label>
                    </div>
                </div>
            </div>
            <div class="thumb-box border rounded position-relative" :class="[!blobUrlPR2 ? 'd-flex justify-content-center align-items-center' : '']">
                <img v-if="blobUrlPR2" id="PR2PATH" :src="blobUrlPR2" alt="事務所紹介写真2">
                <button v-if="blobUrlPR2" id="pr2path_delete" class="btn text-danger position-absolute end-0 border-0" v-on:click="resetImage"><i class="fa-regular fa-circle-xmark fs-5"></i></button>
                <div v-else class="no-image">
                    <i class="fa fa-regular fa-image fa-2x"></i>
                    <div>No Image</div>
                </div>
            </div>
        </div>
        <!-- 事務所紹介写真3 -->
        <div class="form-group col-lg-4">
            <label class="form-label">事務所紹介写真3</label>
            <div class="d-flex mb-3">
                <label class="btn btn-outline-secondary flex-fill py-1">
                    <i class="fa-fw fa-regular fa-image fs-1rem"></i> 画像を選択
                    <input type="file" id="pr3path_temp" class="d-none" accept="image/*" v-on:change="fileSelected">
                </label>
                <div class="text-center flex-fill">
                    <div class="btn-group">
                        <input type="radio" class="btn-check" id="PR3FLG_YES" name="PR3FLG" v-model="_item.PR3FLG" value="1" hidden>
                        <label for="PR3FLG_YES" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR3FLG) ? 'active' : '']" style="width: 70px;">
                        公開
                        </label>
                        <input type="radio" class="btn-check" id="PR3FLG_NO" name="PR3FLG" v-model="_item.PR3FLG" value="0" hidden>
                        <label for="PR3FLG_NO" class="btn btn-outline-primary py-1" :class="[isActive(_item.PR3FLG) ? '' : 'active']" style="width: 70px;">
                        非公開
                        </label>
                    </div>
                </div>
            </div>
            <div class="thumb-box border rounded position-relative" :class="[!blobUrlPR3 ? 'd-flex justify-content-center align-items-center' : '']">
                <img v-if="blobUrlPR3" id="PR3PATH" :src="blobUrlPR3" alt="事務所紹介写真3">
                <button v-if="blobUrlPR3" id="pr3path_delete" class="btn text-danger position-absolute end-0 border-0" v-on:click="resetImage"><i class="fa-regular fa-circle-xmark fs-5"></i></button>
                <div v-else class="no-image">
                    <i class="fa fa-regular fa-image fa-2x"></i>
                    <div>No Image</div>
                </div>
            </div>
        </div>
    </div>
    <!-- PRコメント  -->
    <div class="row g-3 mb-3">
        <div class="form-group">
            <div><label for="item-prcomment" class="form-label">PRコメント</label></div>
            <textarea id="item-prcomment" name="item-prcomment" class="form-control" rows="3" cols="80"
            maxlength="500" placeholder="500文字まで入力できます" v-model="item.PRCOMMENT"></textarea>
        </div>
    </div>
    <!-- サインボード -->
    <div class="row mb-3">
        <signboard-component :mode="'update'" v-bind:items="signboards"></signboard-component>
    </div>
    <div id="paidMemberOnlyArea">
        <div id="MessageForFreeMemberShip" class="text-center my-5">
            <span class="alert-outline py-3"><i class="fa fa-fw fa-solid fa-lock pe-2"></i>これ以降の情報の登録は有料会員限定機能です</span>
        </div>
        <div class="row g-3 mb-3">
            <!-- 会員間代行料(登録代行・書類作成は依頼元) 会員間代行料(車庫証明) -->
            <div class="form-group col-lg-6">
                <div><label class="form-label">会員間代行料(登録代行・書類作成は依頼元)</label></div>
                <div class="table-responsive">
                    <commission-component :mode="'update'" :items="_commissions" @updateFunc="updateCommision"></commission-component>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <div><label class="form-label">会員間代行料(車庫証明)</label></div>
                <div id="agencyfeeComp" class="table-responsive">
                    <agency-fee-component :mode="'update'" :items="_agencyfees" @updateFunc="updateAgency"></agency-fee-component>
                    <police-selector :items="_agencyfees" @updateFunc="updateAgency" :prefs="prefs"></police-selector>
                </div>
            </div>
        </div>
        <!-- 代行依頼時のお願い -->
        <div class="row g-3 mb-3">
            <div class="form-group">
                <label id="item-comment"  class="form-label">代行依頼時のお願い</label>
                <textarea id="item-comment" name="item-comment" class="form-control" rows="3" cols="80"
                maxlength="500" placeholder="500文字まで入力できます" v-model="item.COMMENT"></textarea>
            </div>
        </div>
    </div>
    <!-- 車庫証明.COMへの掲載有無 -->
    <div class="row mb-3">
        <label id="label-GARAGE_FLG" class="form-label">車庫証明.COM</label>
        <div class="form-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="GARAGE_FLG" id="GARAGE_FLG_YES" v-model="item.GARAGE_FLG" value="1" disabled>
                <label class="form-check-label" for="GARAGE_FLG_YES">
                    掲載する
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="GARAGE_FLG" id="GARAGE_FLG_NO" v-model="item.GARAGE_FLG" value="0" disabled>
                <label class="form-check-label" for="GARAGE_FLG_NO">
                    掲載しない
                </label>
            </div>
        </div>
    </div>
    <!-- バイク名義変更.COMへの掲載有無 -->
    <div class="row mb-3">
        <label id="label-BIKE_FLG" class="form-label">バイク名義変更.COM</label>
        <div class="form-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="BIKE_FLG" id="BIKE_FLG_YES" v-model="item.BIKE_FLG" value="1" disabled>
                <label class="form-check-label" for="BIKE_FLG_YES">
                    掲載する
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="BIKE_FLG" id="BIKE_FLG_NO" v-model="item.BIKE_FLG" value="0" disabled>
                <label class="form-check-label" for="BIKE_FLG_NO">
                    掲載しない
                </label>
            </div>
        </div>
    </div>
    <!-- 緑ナンバー.COMへの掲載有無 -->
    <div class="row mb-3">
        <label id="label-GREEN_FLG" class="form-label">緑ナンバー.COM</label>
        <div class="form-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="GREEN_FLG" id="GREEN_FLG_YES" v-model="item.GREEN_FLG" value="1" disabled>
                <label class="form-check-label" for="GREEN_FLG_YES">
                    掲載する
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="GREEN_FLG" id="GREEN_FLG_NO" v-model="item.GREEN_FLG" value="0" disabled>
                <label class="form-check-label" for="GREEN_FLG_NO">
                    掲載しない
                </label>
            </div>
        </div>
    </div>
    <!-- ナンバー再交付.COMへの掲載有無 -->
    <div class="row mb-3">
        <label id="label-REISSUE_FLG" class="form-label">ナンバー再交付.COM</label>
        <div class="form-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="REISSUE_FLG" id="REISSUE_FLG_YES" v-model="item.REISSUE_FLG" value="1" disabled>
                <label class="form-check-label" for="REISSUE_FLG_YES">
                    掲載する
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="REISSUE_FLG" id="REISSUE_FLG_NO" v-model="item.REISSUE_FLG" value="0" disabled>
                <label class="form-check-label" for="REISSUE_FLG_NO">
                    掲載しない
                </label>
            </div>
        </div>
    </div>

    <div class="my-5 text-center">
        <button id="entry" type="button" class="btn btn-danger rounded-pill" v-on:click="updateData(item)">この内容で公開する</button>
    </div>
</template>

<script>
    const IMNG_CLOSE = '0';     //非公開
    const IMNG_OPEN = '1';      //公開

    import Treeselect from "vue3-treeselect";
    import "vue3-treeselect/dist/vue3-treeselect.css";
    import SignboardComponent from './SignboardComponent.vue';
    import CommissionComponent from './CommissionComponent.vue';
    import AgencyFeeComponent from './AgencyFeeComponent.vue';
    import PoliceSelector from './PoliceSelector.vue';
    import { useToast } from "vue-toastification";

    export default {
        name: "App",
        components: {
            Treeselect,
            SignboardComponent,
            CommissionComponent,
            AgencyFeeComponent,
            PoliceSelector,
        },
        props:{
            item: Object,
            commissions: Object,
            agencyfees: Object,
            postareas: Object,
            treedata: Array,
            signboards: Object,
            member: Object,
        },
        data() {
            return {
                _item: this.item,
                _commissions : this.commissions,
                _agencyfees : this.agencyfees,
                tree: {
                    unydata: [],
                    valueConsistsOf: 'LEAF_PRIORITY',
                },
                prefs: [],
                blobPR1: null,      // Blob
                blobPR2: null,      // Blob
                blobPR3: null,      // Blob
                blobUrlPR1: null,   // Blob URL
                blobUrlPR2: null,   // Blob URL
                blobUrlPR3: null,   // Blob URL
                blobExt1: '',     // Blob 拡張子
                blobExt2: '',     // Blob 拡張子
                blobExt3: '',     // Blob 拡張子
                imagefiles: [],     // 画像登録用
            };
        },
        mounted() {
            this.setMember()
            this.getTreeData()
            this.getPrefs()
            this.getimages()
            this.$nextTick(function () {
                document.getElementById('placeholder').remove();
            })
        },
        setup() {
            const toast = useToast();
            return { toast }
        },
        methods : {
            showMessage:function(type,message){
                switch(type){
                    case "success":
                        this.toast.success(message,{
                            timeout: 5000,
                        });
                        break;
                    case "warning":
                        this.toast.warning(message,{
                            timeout: false,
                        });
                        break;
                    case "error":
                        this.toast.error(message,{
                            timeout: false,
                        });
                        break;
                    default:
                        this.toast(message,{
                            timeout: false,
                        });
                        break;
                }
            },
            clearMessage() {
                this.toast.clear();
            },
            setMember(){
                switch(this.member.TYPECD){
                    case '2':
                        // 有料会員
                        this.deleteMessageForFreeMemberShip();
                        break;
                    default:
                        // 無料会員 or 登録なし
                        // コントロールを無効化
                        this.disableControls("input");
                        this.disableControls("select");
                        this.disableControls("textarea");
                        this.disableControls("button");
                        break;
                }
            },
            disableControls(TagName){
                //コントロールを全て無効化する
                let controlItems = document.getElementById("paidMemberOnlyArea").getElementsByTagName(TagName);
                for(const item of controlItems){
                    item.disabled = true;
                }
            },
            deleteMessageForFreeMemberShip(Name){
                //無料会員向けメッセージを削除
                let controlItems = document.querySelectorAll('#MessageForFreeMemberShip');
                for(const item of controlItems){
                    item.remove();
                }
            },
            getTreeData() {
                axios.get(`/api/UnyCombo`)
                .then(({data}) => {this.tree.unydata = data})
            },
            getPrefs() {
                axios.get(`/api/prefs`)
                .then(({data}) => {this.prefs = data})
            },
            isActive(PRFLG) {
                return PRFLG === IMNG_OPEN;
            },
            getimages(){
                // DB登録画像の取得
                //--------------------
                this.loadImage(1, this.item.PR1PATH);
                this.loadImage(2, this.item.PR2PATH);
                this.loadImage(3, this.item.PR3PATH);
            },
            getExt(name){
                //ファイル名から拡張子を切り出す
                return name.split(".").length < 2 ? '' : name.split(".").pop();
            },
            fileSelected(event){
                // 画像ファイル選択時
                //--------------------
                if(event.currentTarget.value.length == 0){
                    //canceled
                    return;
                }
                let file = event.currentTarget.files[0];
                let blob = new Blob([file]);
                const dataUrl = window.URL.createObjectURL(blob);

                switch (event.currentTarget.id){
                    case 'pr1path_temp':
                        this.blobPR1 = blob;
                        this.blobUrlPR1 = dataUrl;
                        this.blobExt1 = this.getExt(file.name);
                        break;
                    case 'pr2path_temp':
                        this.blobPR2 = blob;
                        this.blobUrlPR2 = dataUrl;
                        this.blobExt2 = this.getExt(file.name);
                        break;
                    case 'pr3path_temp':
                        this.blobPR3 = blob;
                        this.blobUrlPR3 = dataUrl;
                        this.blobExt3 = this.getExt(file.name);
                        break;
                }
            },
            resetImage(event) {
                // 画像ファイル削除時
                //--------------------
                switch (event.currentTarget.id){
                    case 'pr1path_delete':
                        this.blobUrlPR1 = null;
                        this.blobPR1 = null;
                        this.blobExt1 = '';
                        break;
                    case 'pr2path_delete':
                        this.blobUrlPR2 = null;
                        this.blobPR2 = null;
                        this.blobExt2 = '';
                        break;
                    case 'pr3path_delete':
                        this.blobUrlPR3 = null;
                        this.blobPR3 = null;
                        this.blobExt3 = '';
                        break;
                }
            },
            getParam() {
                // 編集内容を収集
                //--------------------

                // 画像ファイル
                this.imagefiles.splice(0, this.imagefiles.length);  //全要素削除
                this.item.PR1PATH = null;
                this.item.PR2PATH = null;
                this.item.PR3PATH = null;

                let file_name;
                let file;
                let file_exist;

                // --- 1 ----------------------------------------
                file_name = this.item.OFFICEID + '_img1'
                    + (this.blobExt1 === '' ? '' : '.' + this.blobExt1 );
                file = new File([this.blobPR1], file_name);

                if(this.blobPR1 === null){
                    file_exist = false;
                    this.item.PR1PATH = null;
                }else{
                    file_exist =  true;
                    this.item.PR1PATH = file_name;
                }

                this.imagefiles.push({imgno: 1, file_name: file_name, file: file, file_exist: file_exist,});

                // --- 2 ----------------------------------------
                file_name = this.item.OFFICEID + '_img2'
                    + (this.blobExt2 === '' ? '' : '.' + this.blobExt2 );
                file = new File([this.blobPR2], file_name);

                if(this.blobPR2 === null){
                    file_exist = false;
                    this.item.PR2PATH = null;
                }else{
                    file_exist =  true;
                    this.item.PR2PATH = file_name;
                }

                this.imagefiles.push({imgno: 2, file_name: file_name, file: file, file_exist: file_exist,});

                // --- 3 ----------------------------------------
                file_name = this.item.OFFICEID + '_img3'
                    + (this.blobExt3 === '' ? '' : '.' + this.blobExt3 );
                file = new File([this.blobPR3], file_name);

                if(this.blobPR3 === null){
                    file_exist = false;
                    this.item.PR3PATH = null;
                }else{
                    file_exist =  true;
                    this.item.PR3PATH = file_name;
                }

                this.imagefiles.push({imgno: 3, file_name: file_name, file: file, file_exist: file_exist,});

                file_name = this.item.OFFICEID + '_img1' + (this.blobExt1 === '' ? '' : '.' + this.blobExt1 );
                let file1 = new File([this.blobPR1], file_name);
                file_name = this.item.OFFICEID + '_img2' + (this.blobExt2 === '' ? '' : '.' + this.blobExt2 );
                let file2 = new File([this.blobPR2], file_name);
                file_name = this.item.OFFICEID + '_img3' + (this.blobExt3 === '' ? '' : '.' + this.blobExt3 );
                let file3 = new File([this.blobPR3], file_name);

                //commission
                let p_commission;
                p_commission =  this._commissions.filter(function (item){
                    return (item.KINDS || item.FEE || item.FEE_COMMENT);    //空入力を除外
                });
                let i=1;
                for(let data of p_commission){
                    data.NO = i;        //NO振り直し
                    i++;
                }

                //agencyfees
                let p_agencyfees;
                p_agencyfees =  this._agencyfees.filter(function (item){
                    return (item.GARAGE_FEE || item.RECEIPT_FEE || item.LIGHTCAR_FEE);    //空入力を除外
                });
                i=1;
                for(let data of p_agencyfees){
                    data.NO = i;        //NO振り直し
                    i++;
                }

                //postareas
                let p_postareas=[];
                for(let data of this.treedata){
                    p_postareas.push({
                        'TRS_ID': data
                    });
                }

                //パラメタ
                const formData = new FormData()
                formData.append('id', this.item.OFFICEID)
                formData.append('item', JSON.stringify(this.item))
                formData.append('commissions', JSON.stringify(p_commission))
                formData.append('agencyfees', JSON.stringify(p_agencyfees))
                formData.append('postareas', JSON.stringify(p_postareas))
                formData.append('signboards', JSON.stringify(this.signboards))
                formData.append('member', JSON.stringify(this.member))
                formData.append('file1', file1)
                formData.append('file2', file2)
                formData.append('file3', file3)

                return formData;
            },
            updateData(item) {
                // 編集内容をDB登録
                //--------------------
                this.clearMessage();
                let params = this.getParam();
                const headers = {
                   "content-type": "multipart/form-data" ,
                };
                axios.post('/edit', params, {headers}).then((res) => {
                    if(res.data.result === 'success'){
                        //登録したOFFICEIDを再セットする
                        this.item.OFFICEID = res.data.officeid;
                        this.showMessage('success', '登録しました');
                    }else if(res.data.result === 'error'){
                        let msg = res.data.result_messages.flat().join('\n');
                        this.showMessage('error', msg);
                    }else{
                        this.showMessage('error', '登録に失敗しました');
                    }
                }) .catch(error => {
                    if(error.response.status === 422){
                        let msg = Object.values(error.response.data.errors).flat().join('\n');
                        this.showMessage('warning', msg);
                    }else{
                        this.showMessage('error', '登録に失敗しました');
                    }
                });
            },
            updateCommision(data) {
                this._commissions = data;
            },
            updateAgency(data){
                this._agencyfees = data;
            },
        },
        computed:{
            loadImage: function() {
                return (imgno, path)=>{
                    // DB登録画像の取得
                    //--------------------
                    let officeid = this.item.OFFICEID;
                    let filename = path;

                    if(!path){
                        return;
                    };
                    switch (imgno){
                        case 1:
                            this.blobExt1 = this.getExt(path);
                            break;
                        case 2:
                            this.blobExt2 = this.getExt(path);
                            break;
                        case 3:
                            this.blobExt3 = this.getExt(path);
                            break;
                    }

                    const param = {
                        officeid: officeid,
                        filename: filename,
                        imgno: imgno,
                    };

                    axios.post(
                        "/api/imageshowedit",
                        param,
                        {
                            responseType: "arraybuffer"
                        },
                    )
                    .then((response) => {
                        let blob;
                        let dataUrl;

                        if(response.data.byteLength === 0){
                            //console.debug("ファイル無");
                        }else{
                            //console.debug("ファイルあり");
                            blob = new Blob([response.data]);
                            dataUrl = window.URL.createObjectURL(blob);
                        }

                        switch (imgno){
                            case 1:
                                this.blobPR1 = blob;
                                this.blobUrlPR1 = dataUrl;
                                break;
                            case 2:
                                this.blobPR2 = blob;
                                this.blobUrlPR2 = dataUrl;
                                break;
                            case 3:
                                this.blobPR3 = blob;
                                this.blobUrlPR3 = dataUrl;
                                break;
                        }
                        return true;
                    })
                    .catch((err) => {
                        console.debug("error: ", err);
                    });
                }
            },
        },
    };

</script>
