<template>
    <div v-if="item">
        <div class="d-flex align-items-center">
            <div class="screen-sub-title flex-grow-1">事務所情報</div>
            <div id="requestAgencyButtonWrapper"><request-agency :item="item" :postareas="postareas" :member="member"></request-agency></div>
        </div>
        <div class="d-flex my-2">
            <img class="desc-icon me-2" src ="/images/uny.svg" alt="管轄運輸支局" title="管轄運輸支局" />
            <div class="d-flex flex-wrap gap-1">
                <template v-for="postarea in postareas">
                    <template v-for="transport in postarea.transports">
                        <div class="border border-secondary rounded px-2" name="POSTAREA">{{transport.TRS_NAME}}</div>
                    </template>
                </template>
            </div>
        </div>
        <!-- 事務所名 行政書士名 登録番号 -->
        <div class="row mb-2 jms-dtl-row ps-2 ps-lg-0">
            <div class="col-lg-4 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text" id="label-OFFICENAME">事務所名</span>
                    <input type="text" class="form-control bg-white" name="OFFICENAME" v-bind:value="item.OFFICENAME" aria-label="事務所名" aria-describedby="label-OFFICENAME" readonly>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row" v-for="itemMember in item.members">
                    <div class="col-lg-6 mb-2 mb-lg-0 pe-lg-0">
                        <div class="input-group">
                            <span class="input-group-text" id="label-NAME">行政書士名</span>
                            <input type="text" class="form-control bg-white" name="NAME" v-bind:value="itemMember.NAME" aria-label="行政書士名" aria-describedby="label-NAME" readonly>
                        </div>
                    </div>
                    <div class="col-lg-6 ps-lg-2">
                        <div class="input-group">
                            <span class="input-group-text" id="label-QUALIFICATIONCD">登録番号</span>
                            <input type="text" class="form-control bg-white" name="QUALIFICATIONCD" v-bind:value="itemMember.QUALIFICATIONCD" aria-label="登録番号" aria-describedby="label-QUALIFICATIONCD" readonly>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 郵便番号 住所 -->
        <div class="row mb-2 jms-dtl-row ps-2 ps-lg-0">
            <div class="col-lg-4 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text" id="label-POSTNUM">郵便番号</span>
                    <input type="text" class="form-control bg-white" name="POSTNUM" v-bind:value="item.POSTNUM" aria-label="郵便番号" aria-describedby="label-POSTNUM" readonly>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="input-group">
                    <span class="input-group-text" id="label-ADDRESS">住所</span>
                    <input type="text" class="form-control bg-white" name="ADDRESS" v-bind:value="PREF_ADDRESS" aria-label="住所" aria-describedby="label-ADDRESS" readonly>
                </div>
            </div>
        </div>
        <!-- 電話 FAX 携帯電話 -->
        <div class="row mb-2 jms-dtl-row ps-2 ps-lg-0">
            <div class="col-lg-4 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text" id="label-TEL1">電話</span>
                    <input type="text" class="form-control bg-white" name="TEL1" v-bind:value="item.TEL1" aria-label="電話" aria-describedby="label-TEL1" readonly>
                </div>
            </div>
            <div class="col-lg-4 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text" id="label-FAX">FAX</span>
                    <input type="text" class="form-control bg-white" name="FAX" v-bind:value="item.FAX" aria-label="FAX" aria-describedby="label-FAX" readonly>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="input-group">
                    <span class="input-group-text" id="label-TEL2">携帯電話</span>
                    <input type="text" class="form-control bg-white" name="TEL2" v-bind:value="item.TEL2" aria-label="携帯電話" aria-describedby="label-TEL2" readonly>
                </div>
            </div>
        </div>
        <!-- メールアドレス URL -->
        <div class="row mb-2 jms-dtl-row ps-2 ps-lg-0">
            <div class="col-lg-4 mb-2 mb-lg-0">
                <div class="input-group">
                    <span class="input-group-text" id="label-EMAIL">E-MAIL</span>
                    <input type="text" class="form-control bg-white" name="EMAIL" v-bind:value="item.EMAIL" aria-label="E-MAIL" aria-describedby="label-EMAIL" readonly>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="input-group">
                    <span class="input-group-text" id="label-HP_URL">URL</span>
                    <a class="form-control bg-white" name="HP_URL" v-bind:href="item.HP_URL" target="_blank" aria-label="HP_URL" aria-describedby="label-HP_URL">{{ item.HP_URL }}</a>
                </div>
            </div>
        </div>
        <!-- 事務所紹介写真(最大3枚) -->
        <div class="row mb-2">
            <div class="form-group col-lg-4">
                <label for="JJHUNYUK" class="form-label">事務所紹介</label>
            </div>
        </div>
        <!-- 撮影ボタン 画像出力ボックス -->
        <div class="row mb-3 gap-3 gap-lg-0">
            <div class="col-lg-5 d-flex justify-content-center">
                <div class="thumb-box border rounded">
                    <div id="img-slider" class="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                          <div class="thumb-box border rounded text-center carousel-item active">
                            <div v-if="!getImageURL(1,item) && !getImageURL(2,item) && !getImageURL(3,item)"><p>No Image</p></div>
                            <img v-else-if="getImageURL(1,item)" :id="PR1PATH" :src="[getImageURL(1,item)]" alt="事務所紹介写真1">
                            <img v-else-if="!getImageURL(1,item) && getImageURL(2,item)" :id="PR2PATH" :src="[getImageURL(2,item)]" alt="事務所紹介写真1">
                            <img v-else :id="PR3PATH" :src="[getImageURL(3,item)]" alt="事務所紹介写真1">
                          </div>
                          <div v-if="((getImageURL(1,item) && getImageURL(2,item)) 
                            || (getImageURL(2,item) && getImageURL(3,item)) 
                            || (getImageURL(1,item) && getImageURL(3,item)))" class="thumb-box border rounded text-center carousel-item" >
                            <img v-if="getImageURL(1,item) && getImageURL(2,item)" id="PR2PATH" :src="[getImageURL(2,item)]" alt="事務所紹介写真2">
                            <img v-else id="PR3PATH" :src="[getImageURL(3,item)]" alt="事務所紹介写真2">
                          </div>
                          <div v-if="getImageURL(1,item) && getImageURL(2,item) && getImageURL(3,item)" class="thumb-box border rounded text-center carousel-item">
                            <img id="PR3PATH" :src="getImageURL(3,item)" alt="事務所紹介写真3">
                          </div>
                        </div>
                        <button v-if="((getImageURL(1,item) && getImageURL(2,item)) 
                            || (getImageURL(2,item) && getImageURL(3,item)) 
                            || (getImageURL(1,item) && getImageURL(3,item)))"
                            class="carousel-control-prev" type="button" data-bs-target="#img-slider" data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button v-if="((getImageURL(1,item) && getImageURL(2,item)) 
                            || (getImageURL(2,item) && getImageURL(3,item)) 
                            || (getImageURL(1,item) && getImageURL(3,item)))"
                            class="carousel-control-next" type="button" data-bs-target="#img-slider" data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="form-group">
                    <!-- <div><label for="JJHUNYUK" class="form-label">PRコメント</label></div> -->
                    <textarea id="PRCOMMENT" name="PRCOMMENT" class="form-control h-100"
                    maxlength="500" placeholder="" v-bind:value="item.PRCOMMENT" readonly></textarea>
                </div>
            </div>
        </div>
        <!-- サインボード -->
        <label class="form-label">対応業務</label>
        <div class="row mb-3">
            <signboard-component :mode="'select'" v-bind:items="signboards"></signboard-component>
        </div>
        <div class="row mb-3">
            <!-- 会員間代行料(登録代行・書類作成は依頼元) 会員間代行料(車庫証明) -->
            <div class="form-group col-lg-6">
                <div><label for="JJHUNYUK" class="form-label">会員間代行料(登録代行・書類作成は依頼元)</label></div>
                <div class="table-responsive">
                    <commission-component :mode="'select'" :items="commissions"></commission-component>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <div><label for="JJHJRYUD" class="form-label">会員間代行料(車庫証明)</label></div>
                <div id="agencyfeeComp" class="table-responsive">
                    <agency-fee-component :mode="'select'" :items="agencyfees"></agency-fee-component>
                </div>
            </div>
        </div>
        <!-- 代行依頼時のお願い -->
        <div class="row mb-3">
            <div class="form-group">
                <div><label for="JJHUNYUK" class="form-label">代行依頼時のお願い</label></div>
                <textarea id="COMMENT" name="COMMENT" class="form-control"
                maxlength="500" placeholder="" v-bind:value="item.COMMENT" readonly></textarea>
            </div>
        </div>
        <!-- <div class="row mb-2">
            <div class="input-group">
                <span class="input-group-text" id="label-GARAGE_FLG">車庫証明.COM</span>
                <div class="btn btn-outline-info" v-bind:class="[isComFlg(item.GARAGE_FLG) ? 'active' : '']">
                    {{isComFlg(item.GARAGE_FLG) ? '掲載する':'掲載しない' }}
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="input-group">
                <span class="input-group-text" id="label-BIKE_FLG">バイク名義変更.COM</span>
                <div class="btn btn-outline-info" v-bind:class="[isComFlg(item.BIKE_FLG) ? 'active' : '']">
                    {{isComFlg(item.BIKE_FLG) ? '掲載する':'掲載しない' }}
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="input-group">
                <span class="input-group-text" id="label-GREEN_FLG">緑ナンバー.COM</span>
                <div class="btn btn-outline-info" v-bind:class="[isComFlg(item.GREEN_FLG) ? 'active' : '']">
                    {{isComFlg(item.GREEN_FLG) ? '掲載する':'掲載しない' }}
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="input-group">
                <span class="input-group-text" id="label-REISSUE_FLG">ナンバー再交付.COM</span>
                <div class="btn btn-outline-info" v-bind:class="[isComFlg(item.REISSUE_FLG) ? 'active' : '']">
                    {{isComFlg(item.REISSUE_FLG) ? '掲載する':'掲載しない' }}
                </div>
            </div>
        </div> -->
    </div>
    <div v-else>No Item.</div>
</template>

<script>
    import RequestAgency from './RequestAgency.vue';
    import SignboardComponent from './SignboardComponent.vue';
    import CommissionComponent from './CommissionComponent.vue';
    import AgencyFeeComponent from './AgencyFeeComponent.vue';
    export default {
        name: "App",
        components: {
            RequestAgency,
            SignboardComponent,
            CommissionComponent,
            AgencyFeeComponent,
        },
        props:{
            item: Object,
            commissions: Object,
            agencyfees: Object,
            postareas: Object,
            signboards: Object,
            member: Object,
        },
        data() {
            return {
                //
            };
        },
        mounted() {
            this.$nextTick(function () {
                document.getElementById('placeholder').remove();
            })
        },
        methods : {
            isComFlg(flag) {
                return flag === "1";     //1:ON
            },
            getImageURL(imgno, item) {
                let path;
                let flg;
                switch (imgno){
                    case 1:
                        flg = item.PR1FLG;
                        path = item.PR1PATH
                        break;
                    case 2:
                        flg = item.PR2FLG;
                        path = item.PR2PATH
                        break;
                    case 3:
                        flg = item.PR3FLG;
                        path = item.PR3PATH
                        break;
                    default:
                        return;
                }
                // パスなし、非公開の場合は画像表示しない
                if(!path || !flg || flg =='0'){
                    return null;
                }
                return "/imageshow/" + item.OFFICEID + "/" + imgno;
            },
        },
        computed:{
            PREF_ADDRESS : function(){
                let newAddress = '';
                if(this.item.prefectual){
                    newAddress = this.item.prefectual.TDFK_NAME + this.item.ADDRESS;
                }
                return newAddress;
            },
        },
    };

</script>
