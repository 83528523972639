<template>
    <button id="requestAgencyButton" v-on:click="requestAgency" type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#requestAgencyModal">
        <i id="requestAgencyIcon" class="fa-solid fa-lock"></i> 代行依頼書作成
    </button>

    <div id="request-agency">
        <div class="modal fade" id="requestAgencyModal" tabindex="-1" role="dialog" data-bs-backdrop="true"
                data-bs-keyboard="false" aria-labelledby="basicModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="alert alert-info alert-dismissible fade show" role="alert">
                            <span class="icon-tips">依頼情報を入力してください</span>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div class="row mb-3">
                            <label for="TRS_NAME" class="col-sm-4 col-form-label fw-bold">運輸支局</label>
                            <div class="col-sm-8">
                                <select v-model="TRS_NAME" id="TRS_NAME" name="TRS_NAME" class="form-select w-auto">
                                    <template v-for="postarea in postareas">
                                        <option v-for="transport in postarea.transports" v-bind:value="transport.TRS_NAME">{{ transport.TRS_NAME }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="ADD_DUE_DATE" class="col-sm-4 col-form-label fw-bold">登録希望日</label>
                            <div class="col-sm-8">
                                <input type="date" id="ADD_DUE_DATE" class="form-control" name="ADD_DUE_DATE" v-model="ADD_DUE_DATE" aria-label="登録希望日" aria-describedby="label-ADD_DUE_DATE" style="width: 150px;">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="TYPE" class="col-sm-4 col-form-label fw-bold">種別</label>
                            <div class="col-sm-8">
                                <input type="text" id="TYPE" class="form-control" name="TYPE" v-model="TYPE" aria-label="種別" maxlength=255 aria-describedby="label-TYPE">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="ORDER_DETAIL" class="col-sm-4 col-form-label fw-bold">依頼内容</label>
                            <div class="col-sm-8">
                                <input type="text" id="ORDER_DETAIL" class="form-control" name="ORDER_DETAIL" v-model="ORDER_DETAIL" aria-label="依頼内容" maxlength=255 aria-describedby="label-ORDER_DETAIL">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="OWNER_NAME" class="col-sm-4 col-form-label fw-bold">所有者</label>
                            <div class="col-sm-8">
                                <input type="text" id="OWNER_NAME" class="form-control" name="OWNER_NAME" v-model="OWNER_NAME" aria-label="所有者" maxlength=255 aria-describedby="label-OWNER_NAME">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="USER_NAME" class="col-sm-4 col-form-label fw-bold">使用者</label>
                            <div class="col-sm-8">
                                <input type="text" id="USER_NAME" class="form-control" name="USER_NAME" v-model="USER_NAME" aria-label="使用者" maxlength=255 aria-describedby="label-USER_NAME">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="CAR_BODY_NUMBER" class="col-sm-4 col-form-label fw-bold">登録番号・車台番号</label>
                            <div class="col-sm-8">
                                <input type="text" id="CAR_BODY_NUMBER" class="form-control" name="CAR_BODY_NUMBER" v-model="CAR_BODY_NUMBER" aria-label="登録番号・車台番号" maxlength=255 aria-describedby="label-CAR_BODY_NUMBER">
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
                            id="requestAgencyClose" @click="clearMessage()">キャンセル</button>
                        <button type="button" class="btn btn-primary"
                            id="requestAgencySave" @click="requestAgencyCheck()">入力した内容で依頼書を発行する</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useToast } from "vue-toastification";
    import qs from 'qs';

    const paramsSerializer = (params) => qs.stringify(params);

    export default {
        name: "App",
        components: {
        },
        props: {
            item: Object,
            postareas: Object,
            member: Object,
        },
        data() {
            return {
                TRS_NAME: "",
                ADD_DUE_DATE: "",
                TYPE: "",
                ORDER_DETAIL: "",
                OWNER_NAME: "",
                USER_NAME: "",
                CAR_BODY_NUMBER: "",
            };
        },
        emits: [
            'updateFunc',
        ],
        mounted() {
            this.setMember();
            this.load_event();
        },
        setup() {
            const toast = useToast();
            return { toast }
        },
        methods : {
            showMessage:function(type,message){
                switch(type){
                    case "success":
                        this.toast.success(message,{
                            timeout: 5000,
                        });
                        break;
                    case "warning":
                        this.toast.warning(message,{
                            timeout: false,
                        });
                        break;
                    case "error":
                        this.toast.error(message,{
                            timeout: false,
                        });
                        break;
                    default:
                        this.toast(msg,{
                            timeout: false,
                        });
                        break;
                }
            },
            clearMessage() {
                this.toast.clear();
            },
            setMember(){
                switch(this.member.TYPECD){
                    case '2':
                        // 有料会員
                        document.getElementById("requestAgencyButton").disabled = false;
                        document.getElementById("requestAgencyIcon").className = 'fa-solid fa-lock-open';
                        break;
                    default:
                        // 無料会員 or 登録なし
                        document.getElementById("requestAgencyButton").disabled = true;
                        document.getElementById("requestAgencyButtonWrapper").setAttribute('title', '有料会員限定機能です');
                        document.getElementById("requestAgencyIcon").className = 'fa-solid fa-lock';
                        break;
                }
            },
            load_event(){
                this.initInputs();
                //TODO:初期化
                // $('#requestAgencyModal')[0].addEventListener('hidden.bs.modal', function(){
                //     alert('hidden');
                // });
            },
            initInputs(){
                this.setDDL_TRS_NAME();
                this.ADD_DUE_DATE = "";
                this.TYPE = ""
                this.ORDER_DETAIL = "";
                this.OWNER_NAME = "";
                this.USER_NAME = "";
                this.CAR_BODY_NUMBER = "";
            },
            setDDL_TRS_NAME(){
                //先頭の運輸支局名をセット
                let val='';
                for(const i in this.postareas){
                    for(const j in this.postareas[i].transports){
                        val = this.postareas[i].transports[j].TRS_NAME;
                        break;
                    }
                    break;
                }
                this.TRS_NAME = val;
            },
            getParam(){
                const params = {
                    id: this.item.OFFICEID,
                    inputs: {
                        TRS_NAME: this.TRS_NAME,
                        ADD_DUE_DATE: this.ADD_DUE_DATE,
                        TYPE: this.TYPE,
                        ORDER_DETAIL: this.ORDER_DETAIL,
                        OWNER_NAME: this.OWNER_NAME,
                        USER_NAME: this.USER_NAME,
                        CAR_BODY_NUMBER: this.CAR_BODY_NUMBER,
                    },
                }
                return params;
            },
            requestAgencyCheck(){
                this.clearMessage();
                let params = this.getParam();
                axios.get('/requestAgencyCheck',{
                            params: params,
                            dataType:'json',
                            paramsSerializer,
                        }
                ).then((res) => {
                    if(res.data.status ==='success'){
                        this.requestAgencyIssue(params);
                    }else{
                        let msg = Object.values(res.data.messages).flat().join('\n');
                        this.showMessage('warning', msg);
                    }
                }).catch(error => {
                    if(error.response.status === 422){
                        let msg = Object.values(error.response.data.errors).flat().join('\n');
                        this.showMessage('warning', msg);
                    }else{
                        this.showMessage('error', '発行チェックに失敗しました');
                    }
                });
            },
            requestAgencyIssue(params){
                axios.get('/requestAgencyIssue',{
                            params: params,
                            responseType:'blob',
                            paramsSerializer,
                        }
                ).then((res) => {
                    var blob = new Blob([res.data]);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = '代行依頼書.xlsx'; //res.headers.filename
                    link.click();
                    this.showMessage('success', '発行しました');
                    // document.getElementById("requestAgencyClose").click();
                }).catch(error => {
                    this.showMessage('error', '発行に失敗しました');
                });
            },
        },
        computed: {
            //
        }
    };
</script>
