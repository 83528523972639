<template>
    <div id="signboard-details">

        <!-- 更新 -->
        <label v-if="isUpdateMode" class="form-label">対応業務<span class="info-outline">クリックして選択</span></label>
        <div v-if="isUpdateMode" class="d-flex flex-wrap gap-1">
            <template v-for="(sn,i) in _items" v-bind:key="sn.SIGN_ID">
                <input type="radio" :id="'LIGHT'+String(i)" v-on:click="toggle_switch(sn)" hidden>
                <label :for="'LIGHT'+String(i)" class="btn btn-outline-primary signboard-item" :class="[isLighting(sn.LIGHT) ? 'active' : '']">
                    {{sn.LETTER}}
                </label>
            </template>
        </div>

        <!-- 照会 -->
        <div v-else class="d-flex flex-wrap gap-1">
            <template v-for="(sn,i) in _items" v-bind:key="sn.SIGN_ID">
                <div class="border signboard-item" :class="[isLighting(sn.LIGHT) ? 'bg-primary border-primary text-light' : 'text-secondary']">
                    {{sn.LETTER}}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    const LIGHT_OFF = '0';     //0:消灯
    const LIGHT_ON = '1';      //1:点灯

    export default {
        name: "App",
        components: {
        },
        props: {
            mode: String,
            items: Object,
        },
        data() {
            return {
                _mode: this.mode,
                _items: this.items,
                isActive: false,
            };
        },
        methods : {
            isLighting(light) {
                return light === LIGHT_ON;
            },
        },
        computed: {
            isUpdateMode() {
                return (this._mode === 'update');
            },
            isSelectMode() {
                return (this._mode === 'select');
            },
            toggle_switch: function() {
                return (val)=>{
                    if(val.LIGHT === LIGHT_OFF){
                        val.LIGHT = LIGHT_ON;
                    }
                    else{
                        val.LIGHT = LIGHT_OFF;
                    }
                }
            },
        }
    };
</script>
