<template>
    <div v-if="_items.length" class="card mb-2">
        <p class="card-header list-group-header text-muted">その他の事務所</p>
        <div class="selection list-group-flush">
            <span v-for="item in _items" v-bind:key="item.OFFICEID" class="list-group-item list-group-item-action">
                <a id="name" name="name" v-bind:href="'/inquiry/' + item.OFFICEID" class="caption">{{item.OFFICENAME}}</a>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "App",
        props: {
            myOfficeId: String,
            items: Object,
        },
        computed: {
            _items: function(){
                let ret = [];
                //自分の事務所を除く
                Object.keys(this.items).forEach((key)=>{
                    if(key !== this.myOfficeId){
                        ret.push({
                            OFFICEID: key,
                            OFFICENAME: this.items[key]
                        });
                    }
                });
                return ret;
            }
        },
    };
</script>
