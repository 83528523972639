<template>
    <div class="card my-3 mx-auto">
        <div class="card-body">
            <div class="form-group row mb-3">
                <label for="JJHUNYUK" class="col-sm-4 col-form-label">運輸支局から探す</label>
                <div class="col-sm-8">
                    <treeselect v-model="transportdata" :multiple="true" :disable-branch-nodes="true" :options=unydata placeholder="選択してください" />
                </div>
            </div>
            <div class="form-group row mb-3">
                <label for="JJHJRYUD" class="col-sm-4 col-form-label">管轄警察署から探す</label>
                <div class="col-sm-8">
                    <treeselect v-model="policestadata" :multiple="true" :disable-branch-nodes="true" :options=kssdata placeholder="選択してください" />
                </div>
            </div>
            <div class="form-group row mb-3">
                <label for="JJHKNRID" class="col-sm-4 col-form-label">行政書士事務所から探す</label>
                <div class="col-sm-8">
                    <treeselect v-model="addressdata" :multiple="true" :disable-branch-nodes="true" :options=jmsdata placeholder="選択してください" />
                </div>
            </div>
            <div class="form-group text-center">
                <button id="search-icon" class="btn btn-primary rounded-pill" @click="search_event()"><i class="fa fa-fw fa-search"></i> この条件でさがす</button>
            </div>
        </div>
    </div>

    <div class="card mb-3 mx-auto" v-for="item in items" :key="item.OFFICENAME">
        <div class="card-header d-flex align-items-center">
            <!-- 行政書士事務所名 -->
            <h5 class="fw-bold mb-0 flex-grow-1">{{item.OFFICENAME}}</h5>
            <!-- 詳細 -->
            <a class="btn btn-outline-primary" v-bind:href="'inquiry/' + item.OFFICEID">詳しく見る</a>
        </div>
        <div class="card-body">
            <div class="row g-0">
                <div class="col-md-3">
                    <img v-if="getImageURL(item)" :src="[getImageURL(item)]" class="img-thumbnail d-block mx-md-0 mx-auto" alt="事務所紹介画像">
                    <div v-else class="img-thumbnail no-image mx-md-0 mx-auto d-flex align-items-center justify-content-center"><div><i class="fa fa-regular fa-image fa-2x"></i><div>No Image</div></div></div>
                </div>
                <div class="col-md-9 my-2">
                    <!-- 運輸支局名 -->
                    <div class="card-text mb-2">
                        <div class="d-flex">
                            <img class="desc-icon me-2" src ="/images/uny.svg" alt="運輸支局" title="運輸支局" />
                            <div class="d-flex flex-wrap gap-1">
                                <template v-for="postarea in item.postareas">
                                    <div class="border border-secondary rounded px-2" v-for="transport in postarea.transports">{{transport.TRS_NAME}}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 警察署名 -->
                    <div class="card-text mb-2">
                        <div class="d-flex">
                            <img class="desc-icon me-2" src ="/images/ks.svg" alt="管轄警察署" title="管轄警察署" />
                            <div class="d-flex flex-wrap gap-1">
                                <div class="border border-secondary rounded px-2" v-for="agencyfee in item.agencyfees">{{agencyfee.policestation.KSS_NAME}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 事務所所在地 -->
                    <div class="d-flex">
                        <img class="desc-icon me-2" src ="/images/adr.svg" alt="事務所住所" title="事務所住所" />
                        <p class="card-text">{{item.prefectual.TDFK_NAME}}{{item.ADDRESS}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Treeselect from "vue3-treeselect";
    import "vue3-treeselect/dist/vue3-treeselect.css";
    export default {
        name: "App",
        components: {
            Treeselect,
        },
        props:{
            itemsdata: Object,
            transportdata: Array,
            policestadata: Array,
            addressdata: Array,
        },
        data() {
            return {
                items: [],
                unydata:[],
                kssdata:[],
                jmsdata:[],
            };
        },
        mounted() {
            this.GetData()
            this.load_event()
            this.$nextTick(function () {
                document.getElementById('placeholder').remove();
            })
        },
        methods: {
            GetData() {
                axios.get(`/api/UnyCombo`)
                .then(({data}) => {this.unydata = data})

                axios.get(`/api/KssCombo`)
                .then(({data}) => {this.kssdata = data})

                axios.get(`/api/JmsCombo`)
                .then(({data}) => {this.jmsdata = data})
            },
            load_event(){
                this.items = this.itemsdata
            },
            search_event(){

                let param = this.getparam();

                //検索リクエスト
                location.href = "/?" + param + "#result";

            },
            getparam(page = "") {
                //GETパラメータ生成
                let comboparam = "";

                comboparam = comboparam + "TRS_ID=" + this.transportdata;
                comboparam = comboparam + "&" + "KSS_ID=" + this.policestadata;
                comboparam = comboparam + "&" + "ADDRESS=" + this.addressdata;

                return comboparam;
            },
            getImageURL(item) {
                if(!item.FRONT_IMG_NO){
                    return null;
                }
                return "/imageshow/" + item.OFFICEID + "/" + item.FRONT_IMG_NO;
            },
        },
        computed: {

        }
    };

</script>
