<template>
    <div class="alert alert-info alert-dismissible fade show" role="alert">
        <span class="icon-tips">他会員へ依頼した封印代行の履歴を照会します。最新のものから表示されます。</span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    <div id="history-table" class="mt-3">
        <div class="table-responsive">
            <table class="table table-bordered text-nowrap align-middle">
                <thead>
                    <tr class="text-center">
                        <th scope="col">作成日</th>
                        <th scope="col">登録予定日</th>
                        <th scope="col">種別</th>
                        <th scope="col">依頼内容</th>
                        <th scope="col">所有者</th>
                        <th scope="col">使用者</th>
                        <th scope="col">車体番号</th>
                        <th scope="col">管轄</th>
                        <th scope="col">依頼先事務所名</th>
                    </tr>
                </thead>
                <tbody id="history-info">
                    <tr v-for="item in items" :key="item.CAR_BODY_NUMBER">
                        <!-- 作成日 -->
                        <td>{{formatDate(item.CREATE_DATE)}}</td>
                        <!-- 登録予定日 -->
                        <td>{{formatDate(item.ADD_DUE_DATE)}}</td>
                        <!-- 種別 -->
                        <td class="text-center">{{item.TYPE}}</td>
                        <!-- 依頼内容 -->
                        <td>{{item.ORDER_DETAIL}}</td>
                        <!-- 所有者 -->
                        <td>{{item.OWNER_NAME}}</td>
                        <!-- 使用者 -->
                        <td>{{item.USER_NAME}}</td>
                        <!-- 車体番号 -->
                        <td>{{item.CAR_BODY_NUMBER}}</td>
                        <!-- 管轄 -->
                        <td>{{item.TRS_NAME}}</td>
                        <!-- 依頼先事務所名 -->
                        <td>{{item.ORDER_OFFICE_NAME}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "App",
        components: {},
        props:{
            itemsdata: Object,
        },
        data() {
            return {
                items: [],
            };
        },
        mounted() {
            this.load_event()
            this.$nextTick(function () {
                document.getElementById('placeholder').remove();
            })
        },
        methods: {
            load_event(){
                this.items = this.itemsdata
            },
            formatDate(date) {
                if(!!date) return moment(date).format("YYYY年M月D日")
            },  
        },
        computed: {

        }
    };

</script>
