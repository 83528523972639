<template>
    <table class="table table-bordered text-nowrap align-middle">
        <thead>
            <tr class="text-center">
                <th>管轄</th>
                <th>代行料/税込み</th>
                <th>取得のみ</th>
                <th>軽自動車</th>
                <th v-if="isUpdateMode"></th>
            </tr>
        </thead>
        <tbody id="agencyfee-details">

            <!-- 更新 -->
            <tr v-for="(item,i) in _items" v-bind:key="item.NO" v-if="isUpdateMode" >
                <td>
                    <span id="KSS_NAME" name="KSS_NAME" class="caption">{{item.KSS_NAME}}</span>
                </td>
                <td>
                    <input type="text" id="GARAGE_FEE" name="GARAGE_FEE" class="form-control text-end" maxlength="10" v-model="item.GARAGE_FEE">
                </td>
                <td>
                    <input type="text" id="RECEIPT_FEE" name="RECEIPT_FEE" class="form-control text-end" maxlength="10" v-model="item.RECEIPT_FEE">
                </td>
                <td>
                    <input type="text" id="LIGHTCAR_FEE" name="LIGHTCAR_FEE" class="form-control text-end" maxlength="10" v-model="item.LIGHTCAR_FEE">
                </td>
                <td>
                    <button id="delete" class="btn text-danger border-0" @click="deleteItem(i)"><i class="fa-solid fa-xmark fs-5"></i></button>
                </td>
            </tr>

            <!-- 照会 -->
            <tr v-for="item in _items" v-bind:key="item.NO" v-if="isSelectMode">
                <td>
                    <span id="KSS_NAME" name="KSS_NAME" class="caption">{{item.KSS_NAME}}</span>
                </td>
                <td class="text-end">
                    <span id="GARAGE_FEE" name="GARAGE_FEE" class="caption">{{Number(item.GARAGE_FEE).toLocaleString()}}円</span>
                </td>
                <td class="text-end">
                    <span id="RECEIPT_FEE" name="RECEIPT_FEE" class="caption">{{Number(item.RECEIPT_FEE).toLocaleString()}}円</span>
                </td>
                <td class="text-end">
                    <span id="LIGHTCAR_FEE" name="LIGHTCAR_FEE" class="caption">{{Number(item.LIGHTCAR_FEE).toLocaleString()}}円</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "App",
        components: {
        },
        props: {
            mode: String,
            items: Object,
        },
        data() {
            return {
                _mode: this.mode,
                _items: this.items,
            };
        },
        emits: [
            'updateFunc',
        ],
        methods : {
            addItem() {
                this._items.push({
                    OFFICEID: '',
                    NO: 0,
                    KSS_ID: '',
                    KSS_NAME: '',
                    GARAGE_FEE: '',
                    RECEIPT_FEE: '',
                    LIGHTCAR_FEE: '',
                });
                this.$emit('updateFunc', this._items);
            },
            deleteItem(index){
                this._items.splice(index, 1);
                this.$emit('updateFunc', this._items);
            }
        },
        computed: {
            isUpdateMode() {
                return (this._mode === 'update');
            },
            isSelectMode() {
                return (this._mode === 'select');
            }
        },
    };

</script>


