<template>
    <div id="police-selector">
        <div class="d-flex justify-content-center">
            <button class="btn text-primary border-0 pt-0" data-bs-toggle="modal" data-bs-target="#policeSelectorModal"><i class="fa-solid fa-circle-plus fs-2"></i></button>
        </div>
        <div class="modal fade" id="policeSelectorModal" tabindex="-1" role="dialog" data-bs-backdrop="true"
                data-bs-keyboard="false" aria-labelledby="basicModal" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="alert alert-info alert-dismissible fade show" role="alert">
                            <span class="icon-tips">都道府県選択後、追加する警察署を選択してください。複数選択することもできます。</span>
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div class="mb-3">
                            <select id="select-prefs" class="form-select w-auto" v-model="tdfk_id" name="pref_id" @change="getStations">
                            <option value="" hidden>都道府県を選択してください</option>
                            <option v-for="pref in prefs" v-bind:value="pref.TDFK_ID">{{ pref.TDFK_NAME }} </option>
                            </select>
                        </div>
                        <div class="d-flex flex-wrap gap-1">
                            <template v-for="(station,i) in stations" v-bind:key="station.KSS_ID">
                                <input type="radio" :id="'radio-police-station'+String(i)" v-on:click="toggle_switch(station)" hidden>
                                <label :for="'radio-police-station'+String(i)" class="btn btn-outline-primary kss-item" :class="[station.selected ? 'active' : '']">
                                    {{station.KSS_SHORT_NAME}}
                                </label>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
                            id="policeSelectorClose" @click="clearSelected()">キャンセル</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            id="policeSelectorCloseSave" @click="addItem()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "App",
        components: {
        },
        props: {
            items: Object,
            prefs: Object,
        },
        data() {
            return {
                _items: this.items,
                tdfk_id: "",
                stations: [],
            };
        },
        emits: [
            'updateFunc',
        ],
        mounted() {
            this.GetData()
        },
        methods : {
            GetData() {
                axios.get(`/api/UnyCombo`)
                .then(({data}) => {this.unydata = data})
            },
            addItem() {
                //選択数分 行追加
                for(let key in this.stations){
                    if(this.stations[key].selected){
                        //追加
                        this._items.push({
                            OFFICEID: '',
                            NO: 0,
                            KSS_ID: this.stations[key].KSS_ID,
                            KSS_NAME: this.stations[key].KSS_NAME,
                            GARAGE_FEE: '',
                            RECEIPT_FEE: '',
                            LIGHTCAR_FEE: '',
                        });
                    }
                }
                this.$emit('updateFunc', this._items);
                this.clearSelected();
            },
            getStations(e){
                let id = e.target.value;
                axios.get(`/api/kss`, {params:{tdfk_id: id}})
                .then(({data}) => {
                    for(let key in data){
                        //状態[selected]を追加
                        data[key]["selected"] = false;
                    }
                    this.stations = data
                })
            },
            clearSelected(){
                for(let key in this.stations){
                    this.stations[key]["selected"] = false;
                }
            },
        },
        computed: {
            toggle_switch: function() {
                return (station)=>{
                    station.selected = !station.selected;
                }
            },
        }
    };
</script>
